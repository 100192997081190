import './App.css';
import {Routes, Route, useLocation} from "react-router-dom";
// import { HelmetProvider } from 'react-helmet-async';
import {useEffect, useState} from 'react';
import Home from './pages/Home.jsx';
import LandingPage from './pages/LandingPage.jsx';
import Sweepstakes from './pages/Sweepstakes.jsx';
import Rules from './components/Sweepstakes/Rules.jsx';
import Terms from './components/Sweepstakes/Terms.jsx';
import Error404 from './pages/Error404.jsx';
import FAQ from './pages/FAQ.jsx';
import Printables from './pages/Printables.jsx';
import PrintablesLanding from './components/Printables/PrintablesLanding.jsx';
import PrintableDetails from './components/Printables/PrintableDetails.jsx';
import News from './pages/News.jsx';
import NewsArticle from './components/NewsArticle/NewsArticle.jsx';
import NewsArticlePreview from './components/NewsArticlePreview/NewsArticlePreview.jsx';
import NewsLanding from './components/NewsLanding/NewsLanding.jsx';
import {gotostore} from './utils/gotostore.js';

function App() {
 
  let currSearch = useLocation().search;

  const [appleUrl, setAppleUrl] = useState("");
  const [androidUrl, setAndroidUrl] = useState("");
  const [utmValue, setUtmValue] = useState("");

  const DEFAULT_APPLE_STORE = "https://playpiknik.com"; // "https://pawpatrolacademyappstore.onelink.me/8QSn/90ry5drq"; // "https://apps.apple.com/us/app/paw-patrol-academy/id6444813487";
  const DEFAULT_ANDROID_STORE = "https://playpiknik.com"; // "https://pawpatrolacademyandroid.onelink.me/f1Ym/zh2v4ec4"; //"https://play.google.com/store/apps/details?id=com.originatorkids.paw&pli=1";

  // Extract the utm_source param
  useEffect(() => {
        
    // convert search params keys to lower case
    const searchParams = new URLSearchParams(currSearch);
    const newParams = new URLSearchParams();
    for (const [name, value] of searchParams) { 
      newParams.append(name.toLowerCase(), value);
    }
    // Now get value of utm_source param
    setUtmValue(newParams.get('utm_source'));

  }, [currSearch]);


  /*
  * Set the store URLs and do redirects in new tab
  * 
  * IMPORTANT: When user needs to be redirected without ever seeing the actual site, then we handle it in index.html w/ a separate script (redirectfirst.js).
  * Do it that way to ensure site is never visible / never loads before the redirect (Whereas redirects done in react components usually show site briefly)
  * So, the only redirects we handle here are ones that open a new tab and thus require site to load anyways.
  */
  useEffect(() => {

    /* 
    * IMPORTANT: When triggering the redirect, use the actual literal url string (or a const), not the state var. Why? Because
    * we don't know when the setState fxn has completed, so the state var may be empty at the time of redirect.
    * So we redirect with string literal, while the setState can run async which is used on the urls on the page
    */
    if (utmValue && utmValue.length > 0){
      switch(utmValue) {
        case "gotostore":
          gotostore(false);
          break;
        case "qr01":
          setAppleUrl("https://pawpatrolacademyappstore.onelink.me/8QSn/l087zrw6");
          setAndroidUrl("https://pawpatrolacademyandroid.onelink.me/f1Ym/zd3vfyuo");
          break;
        case "q4pr":
          setAppleUrl("https://pawpatrolacademyappstore.onelink.me/8QSn/dx8dgpfg");
          setAndroidUrl("https://pawpatrolacademyandroid.onelink.me/f1Ym/ymb9rx00");
          break;
        case "metaiosprereg":
          setAppleUrl("https://pawpatrolacademyappstore.onelink.me/8QSn/n9xz7znv");
          setAndroidUrl("https://pawpatrolacademyandroid.onelink.me/f1Ym/ltoyibw6");
          break;
        case "metaanprereg":
          setAppleUrl("https://pawpatrolacademyappstore.onelink.me/8QSn/n9xz7znv");
          setAndroidUrl("https://pawpatrolacademyandroid.onelink.me/f1Ym/ltoyibw6");
          // gotostoreDirectly(false, "https://pawpatrolacademyandroid.onelink.me/f1Ym/ltoyibw6"); // comment this out to let top js redirect instead
          break;
        case "cinema":
          setAppleUrl("https://pawpatrolacademyappstore.onelink.me/8QSn/tk8jtckn");
          setAndroidUrl("https://pawpatrolacademyandroid.onelink.me/f1Ym/r2ut80rg");
          // gotostoreDirectly(false, "https://pawpatrolacademy.onelink.me/7Y9X/welz7mm7"); // Let top js redirect  // this link autodetects device
          break;
        case "websitehero":
          setAppleUrl("https://pawpatrolacademyappstore.onelink.me/8QSn/k7j4o8fn");
          setAndroidUrl("https://pawpatrolacademyandroid.onelink.me/f1Ym/8dniaj4k")
          // gotostoreDirectly(false, "https://pawpatrolacademy.onelink.me/7Y9X/289t5g8m"); // this link autodetects device
          break;
        case "originatorkids":
          setAppleUrl("https://pawpatrolacademyappstore.onelink.me/8QSn/v26a6euc");
          setAndroidUrl("https://pawpatrolacademyandroid.onelink.me/f1Ym/j8yx19mw");
          // gotostoreDirectly(false, "https://pawpatrolacademy.onelink.me/7Y9X/1cq9i8w2"); // this link autodetects device
          break;
        case "auspawstour":
          setAppleUrl("https://pawpatrolacademyappstore.onelink.me/8QSn/ebyvfo34");
          setAndroidUrl("https://pawpatrolacademyandroid.onelink.me/f1Ym/5qudot1g");
          break;
        default:
          // set default store URLs since we don't recognise the 
          // REPLACE THIS WITH APPSFLYER IF NECESSARY
          setAppleUrl(DEFAULT_APPLE_STORE);
          setAndroidUrl(DEFAULT_ANDROID_STORE);
          break;
      }
    } else {
      // Set default store URLs if nothing above matches
      setAppleUrl(DEFAULT_APPLE_STORE);
      setAndroidUrl(DEFAULT_ANDROID_STORE);
    } 
    
  }, [utmValue])


  return (
    // <HelmetProvider>
        <Routes>
          {/* <Route path="/" element={<Home appleUrl={appleUrl} androidUrl={androidUrl} />} />; */}
          <Route index element={<Home appleUrl={appleUrl} androidUrl={androidUrl} />} />;
          <Route path="faq" element={<FAQ appleUrl={appleUrl} androidUrl={androidUrl} />} />;
          <Route path="pawpatroller" element={<LandingPage mode="category" appleUrl={appleUrl} androidUrl={androidUrl} />} />;
          <Route path="sweepstakes" element={<Sweepstakes mode="category" appleUrl={appleUrl} androidUrl={androidUrl} />} />;
          <Route path="sweepstakes/rules" element={<Rules />} />;
          <Route path="sweepstakes/terms" element={<Terms />} />;
          <Route path="news" element={<News mode="landing" appleUrl={appleUrl} androidUrl={androidUrl} />}>
            <Route path="" element={<NewsLanding appleUrl={appleUrl} androidUrl={androidUrl} />} />;
            <Route path="post/:articleId" element={<NewsArticle appleUrl={appleUrl} androidUrl={androidUrl} />} />;
            <Route path="post-preview/:articleId" element={<NewsArticlePreview appleUrl={appleUrl} androidUrl={androidUrl} />} />;
            <Route path="category/:categoryId" element={<News mode="category" appleUrl={appleUrl} androidUrl={androidUrl} />} />;
          </Route>
          <Route path="printables" element={<Printables />}>
            <Route path="" element={<PrintablesLanding appleUrl={appleUrl} androidUrl={androidUrl} />} />;
            <Route path=":printableId" element={<PrintableDetails appleUrl={appleUrl} androidUrl={androidUrl} />} />;
          </Route>
          <Route path="*" element={<Error404 />} />;
        </Routes>
    // </HelmetProvider>  
  );
}

export default App;
